import React from "react";

import logo from './logo.svg';
// import './App.css';

import {
    Switch,
    Route,
    useLocation,
    useHistory,
    Link,
    BrowserRouter,
    Redirect
} from "react-router-dom";


import resumeData from './resumeData.json';
import Resume from './components/Resume';
import Page from "./components/Page"


const ReactModal = require('react-modal')


class App extends React.Component
{

    constructor(props) {
        super(props);
        this.state = {
            useCookies: document.cookie === "" ? undefined : !window['ga-disable-UA-191596070-1']
        }
    }

    cookieChoiceMade()
    {
        return this.state.useCookies !== undefined;
    }

    enableCookies()
    {
        window.gtag('consent', 'default', {
            'analytics_storage': 'granted'
        });
        window['ga-disable-UA-191596070-1'] = false;
        this.setState({useCookies: true});
    }

    disableCookies()
    {
        window.user = Math.random().toString(16).substring(2);
        window['ga-disable-UA-191596070-1'] = true;
        this.setState({useCookies: false});

    }

    CookieModal()
    {
        return (<div className="cookie-modal">
            <ReactModal
                isOpen={true}>
                <div className="modal-page">
                    <div className="modal-content">
                    <h1> Cookies </h1>
                    <p>May I store a cookie for google analytics?</p>
                    <div className="answer">
                        <button onClick={this.enableCookies.bind(this)}>Yes</button>
                        <button onClick={this.disableCookies.bind(this)}>No</button>
                    </div>
                    </div>
                </div>
            </ReactModal>
        </div>)
    }

    render() {
        return (
            <>
                {!this.cookieChoiceMade() && this.CookieModal()}
                {this.cookieChoiceMade() && <BrowserRouter>
                    <Page {...resumeData}>
                        <Route path="/" exact>
                            <Redirect to='/resume/full'/>;
                        </Route>
                        <div>
                            <Resume resume={resumeData}/>
                        </div>
                    </Page>
                </BrowserRouter>
                }
            </>

        );
    }
}

export default App;
