import React from "react";

class ScrollHereOnMount extends React.Component
{
    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }
    componentDidMount()
    {
        this.ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    render()
    {
        return (<span ref={this.ref}></span>);
    }
}

export default ScrollHereOnMount;