import ToggleMore from "./ToggleMore";
import {Route} from "react-router-dom";

export default function Section(props)
{
    let classes = "section";
    if(props.className) classes = props.className + " " + classes;
    return (<div className={classes}>
        <div className="section-title">
            {props.title}
            <ToggleMore parentUrl={props.parentUrl} urlSegment={props.urlSegment} />
        </div>

        <Route path={props.parentUrl + '/' + props.urlSegment}>
            <div className="section-body">
                {props.children}
            </div>
        </Route>
    </div> )
}