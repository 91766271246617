import {
    useHistory,
    useLocation
} from "react-router-dom";


import Header from "./Header";
import Footer from "./Footer";
import ReactGA from "react-ga";


let initialized = false;
export default function Page(props)
{
    function archiveLocation(location)
    {
        console.log('archiving location');
        let pathname = location.pathname;
        let search = location.search;

        function logUrl(clientId)
        {
            let url = 'http://guidocalvano.nl/store/' + clientId +'/' + pathname + search;
            fetch(url, {
                method: 'get',
                crossDomain: true,
                headers: {
                    'Content-Type': 'text/plain',
                }});
        }

        if(window['ga-disable-UA-191596070-1'])
        {
            logUrl(window.user);
            return;
        }

        ReactGA.set({ page: location.pathname });

        ReactGA.pageview(location.pathname);

        window.ga(function(tracker) {
            logUrl(tracker.get('clientId'));
        });
    }

    let l = useLocation();
    let history = useHistory();

    if(!initialized) {
        if(!window['ga-disable-UA-191596070-1']) ReactGA.initialize('UA-191596070-1');

        initialized = true;
        archiveLocation(l);

        history.listen(archiveLocation);
    }



    return (<div className="page-wrapper">
        <div className="page">
            <Header {...props}/>
            <div className="content">
                {props.children}
            </div>
            <Footer />
        </div>
    </div> )
}