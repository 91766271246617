import Section from "./Section";
import Contact from "./Contact";

function Name(props)
{
    return (<span>
        {props.last + ', ' + props.first + ', ' + props.academicTitle}
    </span>)
}

function Address(props)
{
    return (<span>
        {props.street + ' ' + props.houseNumber} <br/>
        {props.postalCode + ' ' + props.city} <br/>
    </span>)
}

function Email(props)
{
    return (<span> {props.email.replace('@', '[AT]').replace('.', '[DOT]')} </span>)
}

export default function PersonalData(props)
{
        return (
                <Section className="personal-data" title="Personal Data" parentUrl={props.parentUrl} urlSegment="personal_data">
                    <div className="section-information">
                        <div className="section-information-row">
                            <div className="section-information-property">
                                Name
                            </div>
                            <div className="section-information-value">
                                <Name {...props.name} academicTitle={props.academicTitle}/>
                            </div>
                        </div>
                        <div className="section-information-row">
                            <div className="section-information-property">
                                Email
                            </div>
                            <div className="section-information-value">
                                {/*<Email email={props.contact.email} />*/}
                                <Contact />
                            </div>
                        </div>
                        <div className="section-information-row">
                            <div className="section-information-property">
                                Date of birth
                            </div>
                            <div className="section-information-value">
                                {props.dateOfBirth}
                            </div>
                        </div>
                        <div className="section-information-row">
                            <div className="section-information-property">
                                Nationalities
                            </div>
                            <div className="section-information-value">
                                {props.nationalities.join(', ')}
                            </div>
                        </div>
                        <div className="section-information-row">
                            <div className="section-information-property">
                                Address
                            </div>
                            <div className="section-information-value">
                                <Address {...props.address} />
                            </div>
                        </div>
                    </div>
                </Section>
             )
}
