import Section from "./Section";
import ProjectList from "./ProjectList";
import React from "react";
import {Route, Switch, useLocation} from "react-router-dom";
import ToggleMore from "./ToggleMore";
import ToggleFull from "./ToggleFull";


function Experience(props)
{
    return (
        <ProjectList title={
            <div className="section-information-row">
            <div className="section-information-property">
                <div className="activity">{props.activity.join(', ')}</div><div className="organisation"> {props.organisation} </div>
            </div>
            <div className="section-information-value">
            {props.startDate && props.startDate + ' /'} {props.endDate}
            </div>
            </div>
        } parentUrl={props.parentUrl} urlSegment={props.organisation} projects={ props.projects} context={props.context}/>


    )

}

export default function ExperienceList(props)
{
    let classes = "experience";
    if(props.className) classes = props.className + " " + classes;

    let loc = useLocation();

    return (<Section className={classes} title={props.title} parentUrl={props.parentUrl} urlSegment={props.urlSegment}>
        <div className="projects table-header">
            <div className="section-title">
                <div className="section-information-row">
                    <div className="section-information-property">
                        <div className="activity">Activity</div><div className="organisation"> Organisation </div>
                    </div>
                    <div className="section-information-value">
                        Period
                    </div>
                </div>
            </div>
        </div>
        {props.experience.filter(experience => experience.type === props.type).map(experience => (<Experience {...experience} parentUrl={props.parentUrl + '/' + props.urlSegment } />))}

        {loc.search && <ToggleFull />}

    </Section>)
}