import ReCAPTCHA from "react-google-recaptcha";
import {withRouter, Switch, Route, Link, useLocation, useHistory} from "react-router-dom";
import PropTypes from 'prop-types';

const React = require('react');
const ReactModal = require('react-modal')

ReactModal.setAppElement('body');

class Contact extends React.Component
{
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }

    constructor(props)
    {
        super(props);
        this.state =
            {
                serverError: "",
                captchaResponse: null,
                from: undefined,
                subject: undefined,
                message: undefined
            };
    }

    sendMail()
    {
        fetch('http://guidocalvano.nl:5000/send/', {
            method: 'post',
            crossDomain: true,
            headers: {
                'Content-Type': 'text/plain',
            },
            body: JSON.stringify(this.state)
        }).then(response => {
            if(response.status !== 200)
            {
                response.text().then(this.handleSendError.bind(this));
                return;
            }
            this.props.history.push('/resume/contact/success/' + this.state.from);
        })
    }

    handleSendError(text)
    {
        let message = JSON.parse(text);

        let stateUpdate = {serverError: message.message};
        console.dir(message);
        if(message.type === 'captchaError') stateUpdate.captchaResponse = null;

        this.setState(stateUpdate);
    }

    fromErrorMessage()
    {
        if(this.state.from === undefined) return "Required";

        if(typeof(this.state.from) !== 'string')
            return "You managed to get something which isn't a string into the from field. Impressive!";
        if(this.state.from.trim() === "")
            return "From field is empty";
        if(!((this.state.from.match(/@/g) || []).length === 1))
            return "Incorrect email address";

        return "";
    }

    subjectErrorMessage() {
        if (this.state.subject === undefined) return "Required";

        if (typeof(this.state.subject) !== 'string')
            return "You managed to get something which isn't a string into the subject field. Impressive!";

        if (this.state.subject.trim() === "")
            return "Subject field is empty";

        return "";
    }

    messageErrorMessage() {
        if(this.state.message === undefined) return "Required";

        if(typeof(this.state.message) !== 'string')
            return "You managed to get something which isn't a string into the message field. Impressive!";

        if(this.state.message.trim() === "")
            return "Message field is empty";

        return "";
    }

    validEmail()
    {
        if(this.state.from === undefined || this.state.subject === undefined || this.state.message === undefined)
            return false;

        return (this.fromErrorMessage() + this.subjectErrorMessage() + this.messageErrorMessage()) === "";
    }

    isHuman()
    {
        return this.state.captchaResponse !== null;
    }

    canSend()
    {
        return this.isHuman() && this.validEmail()
    }

    render(props)
    {
        let search = "";
        if(this.props.location && this.props.location.search)
        {
            search = this.props.location.search;
        }
        return (
            <div className="contact-container">
                <Switch>
                    <Route path="/resume/contact">
                        <ReactModal
                            isOpen={true}
                            contentLabel="Minimal Modal Example"
                        >
                            <Switch>
                                <Route path="/resume/contact/success">
                                    Thank you for your email!
                                    <Link to={"/resume/" + search} >Close</Link>
                                </Route>
                                <>
                                <div>{this.state.serverError}</div>
                                From: {this.fromErrorMessage()}
                                <input    onChange={event => this.setState({from:    event.target.value})} type="text"/>
                                Subject: {this.subjectErrorMessage()}
                                <input    onChange={event => this.setState({subject: event.target.value})} type="text"/>
                                Message: {this.messageErrorMessage()}
                                <textarea onChange={event => this.setState({message: event.target.value})}  />
                                {this.isHuman() && "human"}
                                {this.validEmail() && "valid"}
                                {<button disabled={!this.canSend()} onClick={()=>this.sendMail()}>sendMail</button>}
                                {!this.isHuman() && <ReCAPTCHA
                                    sitekey="6Lc9NXoaAAAAANPTvbAxMY2XOXkrvLegHMgrKsSC"
                                    onChange={response => {
                                        this.setState({captchaResponse: response});
                                    }}
                                />
                                }
                                <Link to={"/resume/" + search}>Cancel</Link>
                                </>
                            </Switch>
                        </ReactModal>
                    </Route>
                    <Link className="ui-input" to={"/resume/contact/" + search}> contact </Link>
                </Switch>
            </div>
        );
    }
}

export default withRouter(Contact);