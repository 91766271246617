import {Switch, Route, Link, useLocation} from "react-router-dom";
import {HiViewList, BiMinus} from "react-icons/all";
import ScrollHereOnMount from "./ScrollHereOnMount";

export default function ToggleMore(props)
{
    let searchTerm = useLocation().search;
    let moreUrl = (props.parentUrl + '/' + props.urlSegment).replace('//', '/');
    let lessUrl = props.parentUrl;

    return (
        <div className="toggleMore toggle">&nbsp;
            <Switch>
                <Route path={moreUrl} exact>
                    <Link to={lessUrl + searchTerm}>less...</Link>
                    <ScrollHereOnMount/>

                </Route>
                <Route path={lessUrl}>
                    <Link to={moreUrl + searchTerm }>more...</Link>
                </Route>
            </Switch>
        </div>

    )
}