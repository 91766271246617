import Section from "./Section";
import {Route, Switch} from "react-router-dom";
import ToggleMore from "./ToggleMore";
import ToggleFull from "./ToggleFull";

import React from "react";

export default function SkillList(props)
{
    return (

            <Section className="skills" title="Skills" parentUrl={props.parentUrl} urlSegment="skills">

                {Object.keys(props.list).map(category =>
                    (<div className="skill-set" key={category + '/full'}><div className="category"> {category} </div><div className="list">{props.list[category].join(', ')}</div></div>))}

                <ToggleFull/>

            </Section>

    );
}