import {Switch, Route, Link, useLocation} from "react-router-dom";
import {BiListMinus, BiListPlus} from "react-icons/all";

export default function ToggleFull(props)
{
    let location = useLocation()
    let searchTerm = location.search;
    let path = location.pathname

    return (
        <div className="toggle-full toggle">&nbsp;
            {searchTerm && <Switch>
                <Route path='/resume/full'>
                    <Link to={path.replace('/resume/full', '/resume') + searchTerm}>{props.filterText || "filter"}... </Link>
                </Route>
                <Route path='/resume'>
                    <Link to={path.replace('/resume', '/resume/full') + searchTerm }>{props.fullText || "full"}... </Link>
                </Route>
            </Switch>}
        </div>

    )
}