import PersonalData from "./PersonalData";
import SkillList from "./SkillList";
import ExperienceList from "./ExperienceList";
import ProjectList from "./ProjectList";
import {Route, Switch, useLocation, useHistory} from "react-router-dom";
import resumeData from "../resumeData.json";
import {RiDeleteBack2Fill} from "react-icons/ri";
import React from "react";

function getQuery(ul)
{
    let searchTerm = ul["search"];
    if(!searchTerm) return {};
    if(searchTerm.length === 0) return {};

    let queryTokens = searchTerm.substr(1).split('&').map(decodeURI);
    let keywords = queryTokens.filter(token => !token.includes('='));
    let beforeTerm = queryTokens.find(token => token.startsWith('before='));
    let before = beforeTerm && beforeTerm.substr("before=".length);
    let afterTerm = queryTokens.find(token => token.startsWith('after='))
    let after = afterTerm && afterTerm.substr("after=".length);

    return {keywords, before, after};
}

function canonic(str)
{
    return str.toLowerCase().normalize('NFKD').replace(/[^\w\s.-_\/]/g, '')
}

function containsKeyword(phrase, keywords)
{
    return keywords.reduce((isTrue, keyword) => canonic(phrase).includes(canonic(keyword)) || isTrue, false);
}

function filterSkills(categorizedSkills, keywords)
{
    if(!keywords) return categorizedSkills;

    let allCategories = Object.keys(categorizedSkills)
    let validSkillCategories = allCategories.filter(category => containsKeyword(category, keywords));
    let validSkillsByCategory = validSkillCategories.reduce((valid, category) => ({...valid, [category]: categorizedSkills[category]}), {});

    let filteredSkillCategories = allCategories.reduce(
        (valid, category) =>
            ({...valid, [category]: categorizedSkills[category].filter(skill => containsKeyword(skill, keywords))}), {});

    let allFiltered = {...filteredSkillCategories, ...validSkillsByCategory};
    return allFiltered
}

function validProject(project, keywords, skills)
{
    if(!keywords) return true;

    if(containsKeyword(project.title, keywords)) return true;
    if(containsKeyword(project.objective, keywords)) return true;
    if(containsKeyword(project.results, keywords)) return true;

    for (let i in project.skills)
    {
        let skill = project.skills[i];
        if(containsKeyword(skill, keywords)) return true;
        if(skills.includes(skill)) return true;
    }

    return false;
}

function filterExperience(experience, before, after, keywords, validSkills)
{
    if(before && experience.startDate > before) return null;
    if(after && experience.endDate < after) return null;

    if(!keywords) return experience;

    let projects = experience.projects.filter(project => validProject(project, keywords, validSkills));
    experience.projects = projects;

    if(projects.length > 0)
    {
        return experience;
    }

    if(containsKeyword(experience.organisation, keywords)) return experience;
    if(containsKeyword(experience.type, keywords)) return experience;

    for (let activity in experience.activity) {
        if (containsKeyword(activity, keywords)) return experience;
    }

    return null;
}

function filterExperiences(experiences, before, after, keywords, validSkills)
{
    let bla = keywords
    let filteredExperiences = experiences
        .map(experience => filterExperience(
            JSON.parse(JSON.stringify(experience)),
            before,
            after,
            bla,
            validSkills)
        )
        .filter(experience => experience !== null);

    return filteredExperiences
}

function filterResume(resumeData, query)
{
    let partFilteredResumeData = JSON.parse(JSON.stringify(resumeData));

    let before = query.before;
    let after = query.after;

    let keywords = query.keywords;

    partFilteredResumeData.skills = filterSkills(partFilteredResumeData.skills, keywords);

    let allValidSkills = Object.keys(partFilteredResumeData.skills)
        .reduce((all, category) => [...all, ...partFilteredResumeData.skills[category]], []);

    let uniqueValidSkills = [...new Set(allValidSkills)];

    partFilteredResumeData.experience = filterExperiences(partFilteredResumeData.experience,  before, after, keywords, uniqueValidSkills);

    return partFilteredResumeData;
}

function toUrl(baseUrl, query)
{
    let termTokens = [];

    if(query.keywords) termTokens = [...termTokens, ...query.keywords];
    if(query.before && query.before.length > 0)  termTokens = [...termTokens, "before=" + query.before];
    if(query.after && query.after.length > 0)  termTokens = [...termTokens, "after="  + query.after];

    let searchTerm = termTokens.join("&");
    if(searchTerm.length > 0) return baseUrl + '?' + searchTerm;

    return baseUrl;
}

function SearchBar(props)
{
    let query = props.query;
    let history = useHistory();
    let location = useLocation();

    function filter(query)
    {
        console.dir(location);
        history.push(toUrl(location.pathname, query));
    }

    function removeTag(tag)
    {
        filter({...query, keywords: query.keywords.filter(kw => kw !== tag)})
    }

    function addTag(tag)
    {
        filter({...query, keywords: [...(query.keywords || []), tag]})
    }

    function setAfter(after)
    {
        filter({...query, after})
    }

    function setBefore(before)
    {
        filter({...query, before})
    }

    return (<div className="search section">
        <div className="section-title">Search</div>
        <div className="search-tags">
        {query && query.keywords && query.keywords.map(kw =>
            <span key={kw} className="search-tag"><span className="text"> {kw}</span> <span className="close" onClick={x=>removeTag(kw)} ><RiDeleteBack2Fill/></span></span>)}
        <input type="text" placeholder="enter a keyword" onKeyDown={event=>event.key === 'Enter' && addTag(event.target.value)} />
        </div>
        <div className="time-range">
            From <input type="text" placeholder="YYYY-MM-DD" onKeyDown={event => event.key === 'Enter' && setBefore(event.target.value) || event} defaultValue={props.query && props.query.before || ""} />
            &nbsp;to <input type="text" placeholder="YYYY-MM-DD" onKeyDown={event => event.key === 'Enter' && setAfter(event.target.value) || event} defaultValue={props.query && props.query.after || ""} />
        </div>
    </div>);
}

export default function Resume(props)
{
    let query = getQuery(useLocation());
    let filteredResume = filterResume(props.resume, query);

    return (
        <Switch>
            <Route path="/resume/full"
                   component={({match})=>
                       <div className="resume">
                       <SearchBar query={query}/>
                           <div className="resume-content">

                           <div className="resume-information">

                           <PersonalData {...props.resume.personalData} parentUrl={match.url} />
                           <ProjectList className="major" title="Highlighted Projects" parentUrl={match.url} urlSegment="major_projects"  filteredText="search results"
                                        projects={
                               props.resume.experience.reduce((all, x) => [...all, ...x.projects], []).filter(x => 'tags' in x && x.tags.includes("major"))
                           }
                           />
                           <SkillList list={props.resume.skills} parentUrl={match.url} searchTerm={match.searchTerm}/>
                           <ExperienceList
                               className="working"
                               type="job"
                               title="Working Experience"
                               experience={props.resume.experience}
                               parentUrl={match.url}
                               urlSegment={"work"} />
                           <ExperienceList
                               className="education"
                               type="education"
                               title="Education"
                               experience={props.resume.experience}
                               parentUrl={match.url}
                               urlSegment={"education"}
                           />

                       </div>
                       </div>
                       </div>
                   }/>

            <Route path="/resume"
                component={({match})=>
                    <div className="resume">
                    <SearchBar query={query}/>
                    <div className="resume-content">
                    <div className="resume-information">
                        <PersonalData {...props.resume.personalData} parentUrl={match.url} />
                        <ProjectList  className="major" title="Found Projects" parentUrl={match.url} urlSegment="major_projects" fullText="highlighted"
                            projects={filteredResume.experience.reduce((all, x) => [...all, ...x.projects], [])}
                        />
                        <SkillList list={filteredResume.skills} parentUrl={match.url} searchTerm={match.searchTerm}/>
                        <ExperienceList
                            className="working"
                            type="job"
                            title="Working Experience"
                            experience={filteredResume.experience}
                            parentUrl={match.url}
                            urlSegment={"work"} />
                        <ExperienceList
                            className="education"
                            type="education"
                            title="Education"
                            experience={filteredResume.experience}
                            parentUrl={match.url}
                            urlSegment={"education"}
                        />

                    </div>
                    </div>
                    </div>

                }/>

        </Switch>
        )
}