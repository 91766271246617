import Section from "./Section";
import {Route, Switch} from "react-router-dom";
import ReactMarkdown from "react-markdown";
import {gfm} from "remark-gfm";

import ToggleMore from "./ToggleMore";
import React from "react";
import ToggleFull from "./ToggleFull";


function Project(props)
{
    return (
        <Section className="project" title={(
            <div className="section-information-row">
                <div className="section-information-property">
                    {props.title}
                </div>
                <div className="section-information-value">
                    {props.objective}
                </div>

            </div>)
        } parentUrl={props.parentUrl} urlSegment={props.title}>
            <div className="project-body">
                <div className="project-sub-section-title"> Description </div>
                <div className="project-sub-section-body"> <p>{props.title}></p> </div>
                <div className="project-sub-section-title"> Objective </div>
                <div className="project-sub-section-body"> <p>{props.objective}</p></div>
                <div className="project-sub-section-title"> Results </div>
                <div className="project-sub-section-body"> {props.results && <ReactMarkdown children={props.results}/>}</div>
                <div className="project-sub-section-title"> Skills </div>
                <div className="project-sub-section-body"> <p> {props.skills && props.skills.join(', ')}</p></div>
            </div>
        </Section>)

}

export default function ProjectList(props)
{
    let classes = "projects";
    if(props.className) classes = props.className + " " + classes;

    return (<Section className={classes} title={props.title} parentUrl={props.parentUrl} urlSegment={props.urlSegment}>
        <div className="context"><ReactMarkdown>{props.context}</ReactMarkdown></div>
        <span className="projects">
        {props.projects.map(project => (<Project key={project.title} {...project} parentUrl={props.parentUrl + '/' + props.urlSegment} />))}
        </span>
        <ToggleFull fullText={props.fullText} filterText={props.filteredText}/>
    </Section>)
}